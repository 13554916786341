.btn {
  @apply trans cursor-pointer border flex items-center justify-center gap-2 rounded-md text-base font-medium tracking-wide py-1.5 px-4;

  &.contained {
    &.primary {
      @apply bg-brand text-white hover:bg-orange-400  border-transparent;
    }

    &.secondary {
      @apply shadow-md bg-stone-100 hover:bg-stone-200  border-transparent;
    }

    &.tertiary {
      @apply bg-primary border-primary  border-transparent;
    }

    &.subtle {
      @apply opacity-60 hover:opacity-100 bg-black border-transparent text-white;
    }
  }

  &.outlined {
    &.primary {
      @apply border-brand text-brand hover:bg-brand hover:text-white;
    }

    &.secondary {
      @apply border-primary text-white bg-primary hover:bg-secondary;
    }
  }

  &:disabled {
    @apply opacity-60 cursor-not-allowed;
  }
}

.react-calendar {
  @apply border-0 rounded-md w-[300px] p-2;
  box-shadow: 0px 4px 16px 0px rgba(165, 163, 174, 0.45);
  .react-calendar__tile {
    @apply rounded-full p-0 h-[42px];
  }

  .react-calendar__tile--active {
    @apply bg-brand text-white;
  }
}

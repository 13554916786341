@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~react-calendar/dist/Calendar.css';
@import '~react-toastify/dist/ReactToastify.css';
@import '~swiper/css';
@import '~swiper/css/navigation';

@import 'ui/button';
@import 'ui/input';
@import 'ui/calendar';

* {
  @apply outline-none;
  font-family: 'SF Pro Text', 'SF Pro Icons', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

@mixin crush-app-mobile-padding {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

body {
  @apply bg-primary text-white;

  &.crush-app-mobile {
    @include crush-app-mobile-padding;
  }
}

::-webkit-scrollbar {
  width: 0;
}

.trans {
  @apply transition-all duration-200 ease-in-out;
}

// Title
.title {
  @apply text-3xl md:text-4xl font-bold bg-gradient-to-r from-[#ffa600] from-40% to-[#ffd48e] to-60% bg-clip-text text-transparent;
}

.subtitle {
  @apply text-base lg:text-lg text-stone-300 [&_br]:max-lg:hidden;
}

// Badge
.badge {
  @apply flex items-center gap-1 p-1.5 px-3 text-xs rounded-md font-semibold uppercase bg-brand;
}

.footer-linear {
  background: linear-gradient((90deg, #ffa60081, #1f1f1f 37.35%, #1f1f1f 62.65%, #ffa60081));
}

mark {
  @apply bg-brand p-[1px] rounded-[2px];
}

.swiper-button-prev,
.swiper-button-next {
  @apply static after:hidden text-brand m-0 w-fit h-fit text-3xl last:rotate-180 p-1 border-brand border-2 rounded-full;
}

.swiper-prev,
.swiper-next {
  @apply static after:hidden text-brand m-0 w-fit h-fit text-3xl last:rotate-180 cursor-pointer p-1 border-brand border-2 rounded-full;
}

.swiper-button-disabled {
  @apply opacity-0;
}

.curvyDevider {
  position: absolute;
  bottom: -100px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;

  @media (max-width: 1240px) {
    bottom: -50px;
  }

  @media (max-width: 800px) {
    bottom: 0px;
  }

  .shape-fill {
    fill: #1c1a1b;
  }

  .path-0 {
    animation: pathAnim-0 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  @keyframes pathAnim-0 {
    0% {
      d: path(
        'M 0,400 L 0,100 C 75.20371006526966,100.25695637238061 150.40742013053932,100.51391274476124 216,105 C 281.5925798694607,109.48608725523876 337.57402954311243,118.20130539333563 407,108 C 476.42597045688757,97.79869460666437 559.2964616970113,68.68086568189624 623,79 C 686.7035383029887,89.31913431810376 731.2401236688424,139.07523187907938 809,148 C 886.7598763311576,156.92476812092062 997.7430436276193,125.01820680178632 1061,109 C 1124.2569563723807,92.98179319821368 1139.78770182068,92.85194091377534 1195,94 C 1250.21229817932,95.14805908622466 1345.10614908966,97.57402954311233 1440,100 L 1440,400 L 0,400 Z'
      );
    }
    25% {
      d: path(
        'M 0,400 L 0,100 C 78.99003778770182,113.277224321539 157.98007557540365,126.55444864307798 230,114 C 302.01992442459635,101.44555135692202 367.0697354860873,63.059429749227064 431,71 C 494.9302645139127,78.94057025077294 557.7409824802473,133.20783236001375 626,144 C 694.2590175197527,154.79216763998625 767.9663345929233,122.10924081071796 825,111 C 882.0336654070767,99.89075918928204 922.3936791480589,110.3552043971144 994,108 C 1065.606320851941,105.6447956028856 1168.4589488148401,90.46994160082446 1248,87 C 1327.5410511851599,83.53005839917554 1383.7705255925798,91.76502919958777 1440,100 L 1440,400 L 0,400 Z'
      );
    }
    50% {
      d: path(
        'M 0,400 L 0,100 C 57.41532119546548,90.5283407763655 114.83064239093096,81.056681552731 195,90 C 275.16935760906904,98.943318447269 378.09275163174163,126.30161456544144 448,120 C 517.9072483682584,113.69838543455856 554.7983510821024,73.73686018550325 611,78 C 667.2016489178976,82.26313981449675 742.7138440398489,130.75094469254552 810,134 C 877.2861559601511,137.24905530745448 936.3462727585024,95.25936104431467 1000,89 C 1063.6537272414976,82.74063895568533 1131.901064926142,112.2116111301958 1206,120 C 1280.098935073858,127.7883888698042 1360.0494675369291,113.89419443490209 1440,100 L 1440,400 L 0,400 Z'
      );
    }
    75% {
      d: path(
        'M 0,400 L 0,100 C 55.45517004465819,82.87255238749572 110.91034008931638,65.74510477499142 172,77 C 233.08965991068362,88.25489522500858 299.81380968739273,127.89213328753004 382,131 C 464.18619031260727,134.10786671246996 561.8344211611129,100.68636207488836 644,91 C 726.1655788388871,81.31363792511164 792.8485056681554,95.36241841291651 847,88 C 901.1514943318446,80.63758158708349 942.7715561662658,51.86396427344556 1007,59 C 1071.2284438337342,66.13603572655444 1158.0652696667812,109.18172449330126 1234,122 C 1309.9347303332188,134.81827550669874 1374.9673651666094,117.40913775334937 1440,100 L 1440,400 L 0,400 Z'
      );
    }
    100% {
      d: path(
        'M 0,400 L 0,100 C 75.20371006526966,100.25695637238061 150.40742013053932,100.51391274476124 216,105 C 281.5925798694607,109.48608725523876 337.57402954311243,118.20130539333563 407,108 C 476.42597045688757,97.79869460666437 559.2964616970113,68.68086568189624 623,79 C 686.7035383029887,89.31913431810376 731.2401236688424,139.07523187907938 809,148 C 886.7598763311576,156.92476812092062 997.7430436276193,125.01820680178632 1061,109 C 1124.2569563723807,92.98179319821368 1139.78770182068,92.85194091377534 1195,94 C 1250.21229817932,95.14805908622466 1345.10614908966,97.57402954311233 1440,100 L 1440,400 L 0,400 Z'
      );
    }
  }

  .path-0 {
    animation: pathAnim-0 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  @keyframes pathAnim-0 {
    0% {
      d: path(
        'M 0,400 L 0,100 C 75.20371006526966,100.25695637238061 150.40742013053932,100.51391274476124 216,105 C 281.5925798694607,109.48608725523876 337.57402954311243,118.20130539333563 407,108 C 476.42597045688757,97.79869460666437 559.2964616970113,68.68086568189624 623,79 C 686.7035383029887,89.31913431810376 731.2401236688424,139.07523187907938 809,148 C 886.7598763311576,156.92476812092062 997.7430436276193,125.01820680178632 1061,109 C 1124.2569563723807,92.98179319821368 1139.78770182068,92.85194091377534 1195,94 C 1250.21229817932,95.14805908622466 1345.10614908966,97.57402954311233 1440,100 L 1440,400 L 0,400 Z'
      );
    }
    25% {
      d: path(
        'M 0,400 L 0,100 C 78.99003778770182,113.277224321539 157.98007557540365,126.55444864307798 230,114 C 302.01992442459635,101.44555135692202 367.0697354860873,63.059429749227064 431,71 C 494.9302645139127,78.94057025077294 557.7409824802473,133.20783236001375 626,144 C 694.2590175197527,154.79216763998625 767.9663345929233,122.10924081071796 825,111 C 882.0336654070767,99.89075918928204 922.3936791480589,110.3552043971144 994,108 C 1065.606320851941,105.6447956028856 1168.4589488148401,90.46994160082446 1248,87 C 1327.5410511851599,83.53005839917554 1383.7705255925798,91.76502919958777 1440,100 L 1440,400 L 0,400 Z'
      );
    }
    50% {
      d: path(
        'M 0,400 L 0,100 C 57.41532119546548,90.5283407763655 114.83064239093096,81.056681552731 195,90 C 275.16935760906904,98.943318447269 378.09275163174163,126.30161456544144 448,120 C 517.9072483682584,113.69838543455856 554.7983510821024,73.73686018550325 611,78 C 667.2016489178976,82.26313981449675 742.7138440398489,130.75094469254552 810,134 C 877.2861559601511,137.24905530745448 936.3462727585024,95.25936104431467 1000,89 C 1063.6537272414976,82.74063895568533 1131.901064926142,112.2116111301958 1206,120 C 1280.098935073858,127.7883888698042 1360.0494675369291,113.89419443490209 1440,100 L 1440,400 L 0,400 Z'
      );
    }
    75% {
      d: path(
        'M 0,400 L 0,100 C 55.45517004465819,82.87255238749572 110.91034008931638,65.74510477499142 172,77 C 233.08965991068362,88.25489522500858 299.81380968739273,127.89213328753004 382,131 C 464.18619031260727,134.10786671246996 561.8344211611129,100.68636207488836 644,91 C 726.1655788388871,81.31363792511164 792.8485056681554,95.36241841291651 847,88 C 901.1514943318446,80.63758158708349 942.7715561662658,51.86396427344556 1007,59 C 1071.2284438337342,66.13603572655444 1158.0652696667812,109.18172449330126 1234,122 C 1309.9347303332188,134.81827550669874 1374.9673651666094,117.40913775334937 1440,100 L 1440,400 L 0,400 Z'
      );
    }
    100% {
      d: path(
        'M 0,400 L 0,100 C 75.20371006526966,100.25695637238061 150.40742013053932,100.51391274476124 216,105 C 281.5925798694607,109.48608725523876 337.57402954311243,118.20130539333563 407,108 C 476.42597045688757,97.79869460666437 559.2964616970113,68.68086568189624 623,79 C 686.7035383029887,89.31913431810376 731.2401236688424,139.07523187907938 809,148 C 886.7598763311576,156.92476812092062 997.7430436276193,125.01820680178632 1061,109 C 1124.2569563723807,92.98179319821368 1139.78770182068,92.85194091377534 1195,94 C 1250.21229817932,95.14805908622466 1345.10614908966,97.57402954311233 1440,100 L 1440,400 L 0,400 Z'
      );
    }
  }

  .path-1 {
    animation: pathAnim-1 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  @keyframes pathAnim-1 {
    0% {
      d: path(
        'M 0,400 L 0,233 C 52.68911027138441,221.26382686362075 105.37822054276882,209.52765372724147 172,217 C 238.62177945723118,224.47234627275853 319.1762281003092,251.15321195465478 396,247 C 472.8237718996908,242.84678804534522 545.9168670559945,207.85949845413947 623,202 C 700.0831329440055,196.14050154586053 781.1563036757128,219.40879422878737 852,238 C 922.8436963242872,256.5912057712126 983.4579182411542,270.5053246307111 1046,255 C 1108.5420817588458,239.49467536928893 1173.0120233596701,194.56990724836828 1239,186 C 1304.9879766403299,177.43009275163172 1372.493988320165,205.21504637581586 1440,233 L 1440,400 L 0,400 Z'
      );
    }
    25% {
      d: path(
        'M 0,400 L 0,233 C 71.82342837512883,213.42871865338373 143.64685675025765,193.85743730676742 208,183 C 272.35314324974235,172.14256269323258 329.23600137409824,169.99896942631398 405,185 C 480.76399862590176,200.00103057368602 575.4091377533495,232.14668498797667 650,236 C 724.5908622466505,239.85331501202333 779.1274476125043,215.41429062177946 830,219 C 880.8725523874957,222.58570937822054 928.0810717966335,254.19615252490553 999,251 C 1069.9189282033665,247.80384747509447 1164.5482652009619,209.80109927859843 1242,201 C 1319.4517347990381,192.19890072140157 1379.725867399519,212.59945036070079 1440,233 L 1440,400 L 0,400 Z'
      );
    }
    50% {
      d: path(
        'M 0,400 L 0,233 C 78.59154929577466,226.61456544142905 157.1830985915493,220.2291308828581 222,228 C 286.8169014084507,235.7708691171419 337.8591549295775,257.6980419099966 392,260 C 446.1408450704225,262.3019580900034 503.3802816901407,244.9787014771556 589,230 C 674.6197183098593,215.0212985228444 788.6197183098593,202.38715218138097 860,208 C 931.3802816901407,213.61284781861903 960.1408450704225,237.47268979732053 1021,240 C 1081.8591549295775,242.52731020267947 1174.8169014084506,223.722088629337 1250,219 C 1325.1830985915494,214.277911370663 1382.5915492957747,223.6389556853315 1440,233 L 1440,400 L 0,400 Z'
      );
    }
    75% {
      d: path(
        'M 0,400 L 0,233 C 73.32394366197181,210.6564754379938 146.64788732394362,188.3129508759876 209,195 C 271.3521126760564,201.6870491240124 322.7323943661972,237.4046719340433 384,236 C 445.2676056338028,234.5953280659567 516.4225352112677,196.06836138783922 587,186 C 657.5774647887323,175.93163861216078 727.5774647887322,194.3218825145998 804,201 C 880.4225352112678,207.6781174854002 963.267605633803,202.64410855376158 1038,199 C 1112.732394366197,195.35589144623842 1179.352112676056,193.10168327035385 1245,199 C 1310.647887323944,204.89831672964615 1375.323943661972,218.94915836482306 1440,233 L 1440,400 L 0,400 Z'
      );
    }
    100% {
      d: path(
        'M 0,400 L 0,233 C 52.68911027138441,221.26382686362075 105.37822054276882,209.52765372724147 172,217 C 238.62177945723118,224.47234627275853 319.1762281003092,251.15321195465478 396,247 C 472.8237718996908,242.84678804534522 545.9168670559945,207.85949845413947 623,202 C 700.0831329440055,196.14050154586053 781.1563036757128,219.40879422878737 852,238 C 922.8436963242872,256.5912057712126 983.4579182411542,270.5053246307111 1046,255 C 1108.5420817588458,239.49467536928893 1173.0120233596701,194.56990724836828 1239,186 C 1304.9879766403299,177.43009275163172 1372.493988320165,205.21504637581586 1440,233 L 1440,400 L 0,400 Z'
      );
    }
  }
}

.customBoxShadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  height: 1em;
  width: 1em;
  background: url('data:image/svg+xml;utf8,<svg fill="currentColor" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M6 18L18 6M6 6l12 12"></path></svg>')
    50% 50% / 1em 1em no-repeat;
}

.customGradient {
  background: rgb(255, 170, 17);
  background: linear-gradient(
    51deg,
    rgba(255, 170, 17, 0.2329306722689075) 0%,
    rgba(231, 155, 18, 0.165703781512605) 11%,
    rgba(160, 111, 23, 0.22452731092436973) 26%,
    rgba(31, 31, 31, 0) 42%
  );
}

.input-wrapper {
  position: relative;
  width: 100%;
  height: 2px;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 0;
    border-top: 2px solid #666;
    border-bottom: 2px solid #666;
    z-index: -1;
    pointer-events: none;
  }
}

.input[type='range'] {
  -webkit-appearance: none;
  height: 2px;
  background-repeat: no-repeat;
  background: transparent;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #ffaa11;
  cursor: pointer;
}

input[type='range'] {
  -webkit-appearance: none;
  height: 7px;
  background: grey;
  border-radius: 5px;
  background-image: linear-gradient(#ffaa11, #ffaa11);
  background-repeat: no-repeat;
}

.slider {
  position: relative;
  > div {
    transition: all 0.3s ease;
    transform: translateX(100%);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;

    &.active {
      opacity: 1;
      transform: translateX(0%);
      position: relative;
      opacity: 1;
    }

    &.prev {
      transform: translateX(-100%);
    }

    &.next {
      transform: translateX(101%);
      opacity: 1;
    }
  }
}

.creationSlider {
  .swiper-slide {
    opacity: 0.2;
    transition: all 0.3s ease-in-out;
    margin-top: 20px;

    &.centered {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.swiper-slide-active {
      opacity: 1 !important;
      scale: 1.1;

      img {
        @apply border-brand border-2;
      }
    }
  }
}

.carouselSlider {
  .swiper-slide {
    transition: all 0.3s ease-in-out;

    &.centered {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.safariArrowBounce {
  @keyframes bounceSafari {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-30px);
    }
  }
  animation: bounceSafari 1.5s infinite;
}

.chromeArrowBounce {
  @keyframes bounceChrome {
    0% {
      transform: rotate(180deg);
    }
    100% {
      transform: translateY(0) rotate(180deg);
    }
    50% {
      transform: translateY(-30px) rotate(180deg);
    }
  }
  animation: bounceChrome 1.5s infinite;
}

.subtitle-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  max-height: 3rem;
}

.gallery-modal {
  .swiper-prev,
  .swiper-next {
    @apply text-white rounded-full p-0 border-0;

    svg {
      @apply size-9;
    }

    path {
      stroke: #666;
      stroke-width: 0.5;
    }
  }
  .swiper-gallery {
    .swiper-wrapper {
      display: flex;
      align-items: center;
    }
  }
}

.Toastify__toast {
  padding: 0 !important;
  .Toastify__toast-body {
    padding: 0 !important;
    margin: 0 !important;
  }
}

div#profileTurnon {
  -webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
}

/* Add these more specific selectors */
.photo-type-select {
  div {
    padding: 0 !important;
    margin: 0 !important;
    gap: 0 !important;
    border: none !important;
    background: transparent !important;
    padding: 0 !important;
    min-height: unset !important;
    box-shadow: none !important;
    font-size: 16px !important;
    font-stretch: 100% !important;
  }
}

.photo-type-select div[class*="-menu"] {
  background: #1f1f1f !important;
  border: 1px solid #363636 !important;
  top: auto !important;
  bottom: 100% !important;
  border-radius: 6px !important;
  margin: 4px 0px !important;
  padding: 4px !important;
  width: auto !important;
  min-width: max-content !important;
  white-space: nowrap !important;
}

.photo-type-select div[class*="-option"] {
  background: #1f1f1f !important;
  padding: 4px !important;
  cursor: pointer !important;
  white-space: nowrap !important;
}

.photo-type-select input {
  padding: 0 !important;
  color: #fff !important;
}

/* Photo Studio Type Select */
.photo-studio-type-select {
  div {
    padding: 0px !important;
  }
}

.photo-studio-type-select div[class*="-control"] {
  background: #1f1f1f !important;
  padding: 0px 8px !important;
  cursor: pointer !important;
}

.photo-studio-type-select div[class*="-option"] {
  background: #1f1f1f !important;
  padding: 8px !important;
  cursor: pointer !important;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.overflow-scroll-gradient {
  position: relative;
}

.overflow-scroll-gradient::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgb(0, 0, 0));
  pointer-events: none;
}

.overflow-scroll-gradient__scroller {
  padding-bottom: 10px;
}
.blog-container {
  display: flex;
  justify-content: center;
  width: 100%;

  .blog-content-wrapper {
    max-width: 720px;
    width: 100%;
    padding: 24px 20px;

    .blog-meta {
      display: flex;
      gap: 16px;
      color: #666;
      font-size: 0.9rem;
      margin: 10px 0;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;

      .time {
        font-size: 12px;
      }
    }

    h1 {
      font-size: 32px;
      line-height: 1.2;
      color: #FFF;
      font-weight: 700;
      letter-spacing: -0.016em;
      font-family: Georgia, serif;

      @media (min-width: 768px) {
        font-size: 42px;
      }
    }

    h2, h3 {
      font-size: 20px;
      line-height: 2;
      color: #D80;
      font-weight: 700;
      font-family: Georgia, serif;
    }

    .feature-image-container {
      margin: 40px 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .feature-image {
        max-width: 400px;
        height: 280px;
        width: 100%;
        object-fit: cover;
        object-position: top;
        margin-bottom: 0.5rem;
      }

      figcaption {
        color: #666;
        font-size: 0.9rem;
      }
    }

    .blog-content {
      font-family: Georgia, serif;
      font-size: 18px;
      letter-spacing: -0.003em;
      @media (min-width: 768px) {
        font-size: 20px;
      }

      p {
        line-height: 1.86;
        margin-bottom: 28px;
        color: rgba(255, 255, 255, 0.9);
        font-weight: 400;
        font-family: Georgia, serif;
      }

      a {
        color: #FA1;
        font-family: Georgia, serif;
        text-decoration-thickness: 1px;
        text-underline-offset: 2px;
      }

      a:hover {
        text-decoration: underline;
      }

      figure {
        margin: 48px 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          max-height: 400px;
          max-width: 100%;
          height: auto;
          width: auto;
        }

        figcaption {
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
          font-size: 0.875rem;
          color: #666;
          margin-top: 12px;
        }
      }
    }
  }
}
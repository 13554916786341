.input-field {
  @apply flex flex-col gap-1;

  label {
    @apply text-sm;
  }

  input,
  textarea {
    @apply trans bg-transparent text-sm py-3 px-4 border border-primary rounded-md w-full bg-primary;
  }
}

input::placeholder, textarea::placeholder {
  @apply text-stone-700 italic
}

.error-msg {
  @apply text-red-400 text-sm pl-1;
}

.field {
  @apply w-full bg-transparent pl-10 pr-4 py-3 border border-secondary rounded-md text-sm text-white;
}

#botBuilder, #cardBuilder {
  .input-field {
    label {
      @apply text-sm text-stone-400 font-bold;
    }
  }

  .checkbox {
    label {
      @apply text-stone-400;
    }
  }
}
